<template>
<div class="personal-data">
	<div class="header acea-row row-between-wrapper" ref="header">
      <div class="black" @click="returnChange()">
        <img src="@assets/images/icon_black.png" alt="" />
      </div>
      <p>个人信息</p>
    </div>
  	<div class="set-container">
		<div class="set-list acea-row row-between">
			<p>账户编号</p>
			<p class="right">{{userInfo.uid}}</p>
		</div>
		<div class="set-list acea-row row-between">
			<p>用户昵称</p>
			<p class="right">
				<input type="text" name="" v-model="nickname" placeholder="请输入昵称" >
			</p>
		</div>
	</div>

	<div class="set-container">
		<div class="set-list acea-row row-between">
			<p>姓名</p>
			<p class="right">
				<input type="text" name="" v-model="realname" placeholder="请输入姓名" >
			</p>
		</div>
		<div class="set-list acea-row row-between">
			<p>邮箱</p>
			<p class="right">
				<input type="text" name="" v-model="emil" placeholder="请输入邮箱" >
			</p>
		</div>
		<!-- <div class="set-list acea-row row-between">
			<p>绑定手机号码</p>
			<p class="right">135****5303 <img src="public/images/icon_right.png" alt="" /></p>
			
		</div> -->
	</div>


	<div class="detail-btn acea-row row-between">
      <div class="right pay bule" :class="firstJum? 'glay':''" @click="submit">
        <p>保存</p>
      </div>
    </div>
</div>
</template>

<script type="text/javascript">

import { getUser,postUserEdit } from "@api/user";
import {Verify_Empty,Verify_mailbox} from '@utils/dy_validate';
export default {
	name:'PersonalEdit',
	data(){
		return {
			userInfo:'',
			nickname:'',
			realname:'',
			emil:'',
			firstJum:false,
		}
	},
	mounted(){
		this.User();
	},
	methods:{
		returnChange() {
	      var that = this;
	      that.$router.go(-1);
	    },
	    User: function() {
	      let that = this;
	      getUser().then(res => {
	        that.userInfo = res.data;
	        that.nickname = res.data.nickname;
	        that.realname = res.data.real_name;
	        that.emil = res.data.email;
	      });
	    },
		submit: function() {
			var that = this;
			if(that.firstJum==false) {
				if(Verify_Empty(that.nickname)) {
					this.$dialog.toast({ mes: "请输入昵称" });
					return false;
				} else if(Verify_Empty(that.realname)){
					this.$dialog.toast({ mes: "请输入真实姓名" });
					return false;
				} else if (Verify_Empty(that.emil)) {
					this.$dialog.toast({ mes: "请输入邮箱" });
					return false;
				} else if (!Verify_mailbox(that.emil)) {
					this.$dialog.toast({ mes: "请输入正确的邮箱" });
					return false;
				}
				that.firstJum =  true;
			    postUserEdit({
			        nickname:that.nickname,
			        real_name:that.realname,
			        email:that.emil,
			        avatar: this.userInfo.avatar
			    }).then(
			        res => {
			          that.firstJum = false;
			          this.$store.dispatch("USERINFO", true);
			          this.$dialog.success(res.msg);
			          // this.$router.go(-1);
			        },
			        error => {
			          that.firstJum =  false;
			          this.$dialog.error(error.msg);
			        }
			    );
			}
	    },
	}
}


</script>

<style type="text/css">
.personal-data { padding-top:50px; }

.personal-data .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
}
.personal-data .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
}
.personal-data .header .black img {
  width: 100%;
  height: 100%;
}
.personal-data .header p {
  width: calc(100% - 17px);
  text-align: center;
  color: #333;
}
</style>



